import selfOrClosest from "../libs/self-or-closest";
import gsap from "gsap";

class SlideCarousel {
  constructor(container) {
    this.container = container;

    this.activeIndex = 0

    this.slideNavItems = [...this.container.querySelectorAll('.feature-carousel__slide-navigation-item')]

    this.slideBackgrounds = this.container.querySelectorAll('[data-slide-background-index]')
    this.slideCards = this.container.querySelectorAll('[data-slide-card-index]')

    this.handleNavigationClick = this.handleNavigationClick.bind(this)
  }

  handleNavigationClick(event) {
    const button = selfOrClosest(event.target, 'button')

    this.goToSlide(button.dataset.slideNavIndex)
  }

  goToSlide(index) {

    if (index == this.activeIndex) {
      return;
    }

    this.slideBackgrounds[this.activeIndex].classList.remove('active')
    this.slideCards[this.activeIndex].classList.remove('active')

    this.slideCards[index].classList.add('active')
    this.slideBackgrounds[index].classList.add('active')

    gsap.set(this.slideCards[index], { x: '100%', })
    gsap.to(this.slideCards[index], { x: 0, ease: "power4.inOut", duration: 1 })

    gsap.set(this.slideBackgrounds[index], { x: '100%' })
    gsap.to(this.slideBackgrounds[index], { x: 0, ease: "power4.inOut", duration: 1 })

    this.activeIndex = index
  }

  init() {
    this.slideNavItems.forEach((navItem) => {
      navItem.addEventListener('click', this.handleNavigationClick)
    })
  }
}

export default SlideCarousel;
