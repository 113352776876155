import gsap from 'gsap'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import selfOrClosest from '../../libs/self-or-closest';
import Cookies from 'js-cookie'

class IntroSequence {

  constructor() {
    this.container = document.querySelector('.homepage-intro')
    this.videoContainer = this.container.querySelector('.homepage-intro__video')

    this.handleVideoEnded = this.handleVideoEnded.bind(this)
    this.handleContainerTransitionEnd = this.handleContainerTransitionEnd.bind(this)
  }

  handleContainerTransitionEnd(event) {
    if (selfOrClosest(event.target, '.homepage-intro') == this.container) {
      this.container.removeEventListener(this.handleContainerTransitionEnd)
      document.body.classList.remove('intro')
      document.body.classList.remove('intro--out')

    }
  }

  handleVideoEnded() {
    document.body.classList.add('intro--out')
    // document,querySelector('.hero--homepage video').play()
    this.container.addEventListener('transitionend', this.handleContainerTransitionEnd)
    clearAllBodyScrollLocks()
  }

  loadVideo() {
    this.introVideo = this.container.querySelector('video')
    this.introVideo.src = 'https://player.vimeo.com/progressive_redirect/playback/890401089/rendition/240p/file.mp4?loc=external&signature=7debf82a8dc5ac92d2d36932af461c53537f83b4ed87e0c6e054ae73a276805a';
  }

  init() {

    if (!document.body.classList.contains('intro')) {
      return;
    }

    try {
      if (sessionStorage.getItem('homepage_intro_displayed', true)) {
        document.body.classList.remove('intro')
        return;
      }
    } catch(e) {
      console.warn(e)
    }


    try {
      sessionStorage.setItem('homepage_intro_displayed', true);
    } catch(e) {
      console.warn(e)
    }

    // Intro doesn't need a logo in the navigation
    document.body.classList.add('intro-nav')

    this.loadVideo()
    this.introVideo.pause()

    this.introVideo.addEventListener('ended', this.handleVideoEnded)
    disableBodyScroll(this.container)
  }

  play() {
    if (this.introVideo) {
      this.introVideo.play()
    }
  }
}

export default IntroSequence
