import IntroSequence from './intro-sequence'
import { initFeatureCarousel } from '../../feature-carousel';

window.addEventListener('PageContentLoaded', () => {
  const sequence = new IntroSequence()
  sequence.init()
  sequence.play()

  initFeatureCarousel()
});
