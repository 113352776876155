import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';
import FeatureCarousel from './feature-carousel';

class FeatureCarouselPagination extends FeatureCarousel {

  getSwiperConfiguration() {

    this.navigationContainer = document.querySelector('[data-navigation-items]')
    this.navigationIcons = document.querySelector('[data-navigation-icons]')
    let menu = [];
    let menuIcons = [];


    try {
      menu = JSON.parse(this.navigationContainer.dataset.navigationItems);
      menuIcons = JSON.parse(this.navigationContainer.dataset.navigationIcons);
    } catch (e) {
      console.warn(e)
    }

    const config = super.getSwiperConfiguration();

    config.loop = true;
    config.modules = [Autoplay, Keyboard, Pagination, Navigation];

    config.pagination = {
      el: '.feature-carousel__navigation-content',
      clickable: true,
      renderBullet: function (index, className) {
        if (typeof menuIcons[index] !== 'undefined') {
          return `<span class="${className} feature-carousel__navigation-item">
            <span class="c-icon c-icon--${(menuIcons[index])}">
              <svg aria-hidden="true">
                <use xlink:href="/img/svg/sprites/global.svg#${(menuIcons[index])}"></use>
              </svg>
            </span>
            <span class="heading">
              <span class="heading__content">${(menu[index])}</span>
            </span>
          </span>`;
        }

        return `<span class="${className} feature-carousel__navigation-item">
          <span class="heading">
            <span class="heading__content heading__content--texture">${(menu[index])}</span>
          </span>
          <img src="/img/pinnacle/global/filigree-union.png" alt="" class="c-icon c-icon--filigree c-icon--filigree-union feature-carousel__navigation-filigree">
          <span class="c-icon c-icon--diamond" style="">
            <svg aria-hidden="true">
              <use xlink:href="/img/svg/sprites/global.svg#diamond"></use>
            </svg>
          </span>
        </span>`;
      },
    }

    return config;
  }
}

export default FeatureCarouselPagination;
