let carousels = [];
let carouselPaginations = [];

import FeatureCarousel from './feature-carousel';
import FeatureCarouselPagination from './pagination-carousel';

export const initFeatureCarousel = () => {

  if (carousels) {
    carousels.forEach((carousel) => {
      carousel.unload()
    })
  }

  if (carouselPaginations) {
    carouselPaginations.forEach((carousel) => {
      carousel.unload()
    })
  }

  carouselPaginations = [...document.querySelectorAll('.feature-carousel-pagination')].map((element) => {
    const carousel = new FeatureCarouselPagination(element);
    carousel.init()
    return carousel;
  })

  carousels = [...document.querySelectorAll('.feature-carousel:not(.feature-carousel-pagination)')].map((element) => {
    const carousel = new FeatureCarousel(element);
    carousel.init()
    return carousel;
  })
}
