import Swiper from 'swiper';
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';
import selfOrClosest from '../libs/self-or-closest';
import SlideCarousel from './slide-carousel';

class FeatureCarousel {
  constructor(container) {
    this.container = container
    this.navArrows = [...this.container.querySelectorAll('.feature-carousel__arrow')]
    this.navItems = [...this.container.querySelectorAll('.feature-carousel__navigation button')]

    this.enableNavigation = this.container.hasAttribute('data-carousel-navigation');
    this.enableCentered = this.container.hasAttribute('data-carousel-centered');
    this.enableDeeplinks = this.container.hasAttribute('data-carousel-deeplink');

    this.handleSwiperInit = this.handleSwiperInit.bind(this)
    this.handleSwiperSlideChange = this.handleSwiperSlideChange.bind(this)
    this.handleSlideChangeTransitionEnd = this.handleSlideChangeTransitionEnd.bind(this)
  }

  handleSwiperInit() {
    [...document.querySelectorAll('.feature-carousel__slide--carousel')].forEach((slideCarousel) => {
      const carousel = new SlideCarousel(slideCarousel)
      carousel.init()
    })
  }

  handleSlideChangeTransitionEnd(swiper) {

    if (!this.enableDeeplinks) {
      return;
    }

    const activeSlide = swiper.slides.find((slide) => slide.classList.contains('swiper-slide-active'))

    if (activeSlide.dataset.slideUri) {
      window.location.hash = activeSlide.dataset.slideUri
    }
  }

  handleSwiperSlideChange() {

  }

  getSwiperConfiguration() {

    const initialSlideUri = (window.location.hash || '').substring(1)

    let initialSlideIndex = 0;

    if (initialSlideUri.length > 0) {
      const slide = this.container.querySelector(`[data-slide-uri=${initialSlideUri}]`)
      if (slide) {
        initialSlideIndex = parseInt(slide.dataset.slideIndex)
      }
    }

    return {
      modules: [Autoplay, Keyboard, Pagination, Navigation],
      speed: 1000,
      keyboard: {
        enabled: true,
      },
      loop: this.enableNavigation ? false : true,
      spaceBetween: 23,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      navigation: {
        nextEl: '.feature-carousel__next',
        prevEl: '.feature-carousel__previous',
      },
      centeredSlides: this.enableCentered ? true : false,
      initialSlide: initialSlideIndex,
      slidesPerView:'auto',
      watchSlidesProgress: true,
      on: {
        afterInit: this.handleSwiperInit,
        slideChange: this.handleSwiperSlideChange,
        slideChangeTransitionEnd: this.handleSlideChangeTransitionEnd,
      }
    }
  }

  init() {
    console.log(this.getSwiperConfiguration())
    this.swiper = new Swiper(this.container, this.getSwiperConfiguration());
  }
}

export default FeatureCarousel;
